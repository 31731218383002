<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <b-link class="text-decoration-none" append>
            <b-card no-body :class="'text-center bg-dark height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="3" class="text-light align-self-center text-center vertical">
                  <i class="fas fa-users fa-3x"></i>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                    <span class="align-middle">Socios</span>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                     <b-badge class="align-middle" variant="light"> <span class="h3">{{number_home.partners}}</span> </b-badge>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>

          <b-col md="3">
            <b-link class="text-decoration-none" append>
            <b-card no-body :class="'text-center bg-warning height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="3" class="text-light align-self-center text-center vertical">
                  <i class="fas fa-hand-holding-usd fa-3x"></i>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                    <span class="align-middle">Prestamos Pendientes</span>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                     <b-badge class="align-middle" variant="light"> <span class="h3">{{number_home.loans_slopes}}</span> </b-badge>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>

          <b-col md="3">
            <b-link class="text-decoration-none" append>
            <b-card no-body :class="'text-center bg-primary height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="3" class="text-light align-self-center text-center vertical">
                  <i class="fas fa-hand-holding-usd fa-3x"></i>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                    <span class="align-middle">Prestamos Activos</span>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                     <b-badge class="align-middle" variant="light"> <span class="h3">{{number_home.loans_actives}}</span> </b-badge>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>

          <b-col md="3">
            <b-link class="text-decoration-none" append>
            <b-card no-body :class="'text-center bg-success height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="3" class="text-light align-self-center text-center vertical">
                  <i class="fas fa-hand-holding-usd fa-3x"></i>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                    <span class="align-middle">Prestamos Finalizados</span>
                </b-col>
                <b-col md="3" class="text-light align-self-center text-center">
                     <b-badge class="align-middle" variant="light"> <span class="h3">{{number_home.loans_finished}}</span> </b-badge>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>


          
        </b-row>
      </b-col>


 
      <b-col md="12">
        <b-row>
                <b-col md="12" class="mb-1">
                  <div class="w-100 text-center">Accesos Directos</div>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none"  :to="{ path: '/socio/listar' }" append>
                  <CWidgetIcon header="Listar" text="Socios" color="gradient-primary">
                    <img src="@/assets/icons/cliente.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none"  :to="{ path: '/simulador-prestamo/nuevo' }" append>
                  <CWidgetIcon header="Simulador" text="Prestamo" color="gradient-info">
                    <img src="@/assets/icons/prestamo.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none" :to="{ path: '/aporte/listar' }" append>
                  <CWidgetIcon header="Listar" text="Aportes" color="gradient-success">
                    <img src="@/assets/icons/aporte.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none" :to="{ path: '/sepelio/listar' }" append>
                  <CWidgetIcon header="Listar" text="Sepelios" color="gradient-danger">
                    <img src="@/assets/icons/sepelio.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                
                
          
                <b-col md="3">
                  <b-link class="text-decoration-none"  :to="{ path: '/pago-prestamo/nuevo' }" append>
                  <CWidgetIcon header="Nuevo" text="Pago" color="gradient-primary">
                    <img src="@/assets/icons/ventas.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none"  :to="{ path: '/aporte/nuevo' }" append>
                  <CWidgetIcon header="Nuevo" text="Aporte" color="gradient-info">
                    <img src="@/assets/icons/ventas.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none" :disabled="!Permission('BoxAdd')" :to="{ path: '/caja/nuevo' }" append>
                  <CWidgetIcon header="Nueva" text="Caja" color="gradient-success">
                    <img src="@/assets/icons/caja.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="3">
                  <b-link class="text-decoration-none" :to="{ path: '/liquidacion-aporte/listar' }" append>
                  <CWidgetIcon header="Listar" text="Liquidaciones" color="gradient-danger">
                    <img src="@/assets/icons/liquidacion.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
        </b-row>
      </b-col>
        
    </b-row>

    <ModalReservation />
  </div>
</template>
<style scoped>
  .height-card {
    height: 4.5rem;
  }
</style>
<script>

const axios = require("axios").default;
const je = require("json-encrypt");
import { mapState } from "vuex";

import EventBus from '@/assets/js/EventBus';
import ModalReservation from './components/ModalReservation'


export default {
  name: 'Dashboard',

  components: {
   ModalReservation,
  },
  data () {
    return {
      number_home :{
        partners:0,
        loans_slopes:0,
        loans_actives:0,
        loans_finished:0,
      },
      name :'',


      service_expiration : [],
      modal_expiration: false,
    }
  },
   mounted () {
    EventBus.$on('RefreshNumberHome', () => {
      this.NumberHome();
    });
    this.NumberHome();
  },
  methods: {
    modalReservation,
    Permission,
    NumberHome,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
}


function modalReservation() {
  EventBus.$emit('ModalReservationShow');
}
function NumberHome() {
  let me = this;
  let url = this.url_base + "home/total-home";
  axios({
    method: "GET",
    url: url,
  })
  .then(function (response) {
      if (response.data.status == 200) {
        me.number_home.partners = parseFloat(response.data.partners);
        me.number_home.loans_slopes = parseFloat(response.data.loans_slopes);
        me.number_home.loans_actives = parseFloat(response.data.loans_actives);
        me.number_home.loans_finished = parseFloat(response.data.loans_finished);
      }
  })
}

function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}

</script>
